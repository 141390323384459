<template>
    <AButton
        @click="btnDownload"
        :loading="state.loading">Unduh Template</AButton>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import apiClient from '@/services/axios'
import moment from 'moment'

export default defineComponent({
    props: {
        url: {
            default: () => '',
            type: String,
        },
        namefile: {
            default: 'Download-Template',
            type: String,
        },
        params: {
            default: () => ({}),
            type: Object,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
        })

        const btnDownload = () => {
            state.loading = true
            apiClient
                .get(props.url, {
                    params: props.params,
                    responseType: 'blob',
                    headers: {},
                })
                .then(({ data }) => {
                    const url = URL.createObjectURL(
                        new Blob([data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${props.namefile}_${moment().format('DDMMYY_HHmm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        emit('update:errors', `Kode error ${status}, ${statusText} : ${message}`)
                    }
                })
                .finally(() => {
                    state.loading = false
                })
        }

        return {
            btnDownload,
            state,
        }
    },
})
</script>
